import { Button, Box, Paper, Container } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { InputField } from "../common/formElements";
import { useState } from "react";
import { validateField, validateForm } from "../utils/validation";
import { toast } from "react-toastify";
import { login } from "../../services/authService";
// import { ReactComponent as IconAvatar } from "../../images/account.svg";
import Logo from "../../images/logo.jpg";
import { schema } from "../utils/userSchema";

function LoginForm() {
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  function handleChange(e) {
    const val = e.target.value;
    const name = e.target.name;
    const errorMessage = validateField(name, val, schema);
    const tmpErrors = { ...errors };
    if (errorMessage) tmpErrors[name] = errorMessage;
    else delete tmpErrors[name];

    const tmpData = { ...data };
    tmpData[name] = val;
    setErrors(tmpErrors);
    setData(tmpData);
  }

  async function handleSubmit() {
    const tmpErrors = validateForm(data, schema);

    setErrors(tmpErrors || {});
    if (tmpErrors) return;

    //Call backend for post to
    //login
    try {
      const { data: jwt } = await login(data);
      console.log(jwt);
      window.localStorage.setItem("token", jwt);
      window.location.replace("/feed");
    } catch (err) {
      if (
        (err.response && err.response.status === 400) ||
        err.response.status == 409
      ) {
        toast.error(err.response.data);
      }
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Paper elevation={2} sx={{ width: 500 }}>
        <Box>
          <Box textAlign="center">
            {/* <IconAvatar width={150} height={150} /> */}
            {/* <Logo /> */}
            <img src={Logo} alt="Logo" width={300} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            paddingLeft={3}
            paddingRight={3}
          >
            <InputField
              error={errors.username ? true : false}
              helperText={errors.username}
              size="small"
              label="Username"
              icon={EmailOutlinedIcon}
              name="username"
              value={data.username}
              onChange={handleChange}
            />
            <InputField
              error={errors.password ? true : false}
              helperText={errors.password}
              type="password"
              size="small"
              label="Password"
              icon={LockOpenOutlinedIcon}
              name="password"
              value={data.password}
              onChange={handleChange}
            />
            <Box textAlign="center" margin={2}>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default LoginForm;
