import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Layout from "../layout/Layout";
import LoginForm from "./LoginForm";

function LandingPage() {
  // useEffect(() => {
  //   async function getAllBooks() {
  //     // const { data } = await getBooks();
  //     setBooks(data);
  //   }

  //   getAllBooks();
  // }, []);

  return (
    <React.Fragment>
      <Layout>
        <LoginForm></LoginForm>
      </Layout>
    </React.Fragment>
  );
}

export default LandingPage;
