import axios from "axios";
import { toast } from "react-toastify";
import { isLoggedIn, logout } from "../components/utils/loginTools";

// function isLoggedIn() {
//   return window.localStorage.getItem("token");
// }

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    toast.error("An unexpected error occurrred.");
  }
  if (error.response && error.response.status == 401 && isLoggedIn()) {
    console.log("Im here");
    window.localStorage.removeItem("token");
    window.location = "/";
  }

  // toast.error("An unexpected error occurrred.");
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
