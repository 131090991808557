import * as React from "react";
import { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

import { InputField } from "../common/formElements";
import { SelectField } from "../common/formElements";
import { getTypeEnum } from "../../services/menuItemsService";
import { getAsquisitionEnum } from "../../services/menuItemsService";
import { getCollectionEnum } from "../../services/menuItemsService";
import { validateField, validateForm } from "../utils/validation";
import { schema } from "../utils/bookSchema";
import { getDepartmentEnum } from "../../services/menuItemsService";
import { getCurrenciesEnum } from "../../services/menuItemsService";

function BookForm(props) {
  const { open, book, onClose, onSubmit, onChange, onClearForm, useCase } =
    props;
  const [errors, setErrors] = useState({});

  const typeMenuItems = getTypeEnum();
  const collectionMenuItems = getCollectionEnum();
  const acquisitionMenuItems = getAsquisitionEnum();
  const departmentMenuItems = getDepartmentEnum();
  const currencyMenuItems = getCurrenciesEnum();

  function change(e) {
    const val = e.target.value;
    const name = e.target.name;

    if (useCase != "SEARCH") {
      //validate
      const errorMessage = validateField(name, val, schema);
      const tmpErrors = { ...errors };
      if (errorMessage) tmpErrors[name] = errorMessage;
      else delete tmpErrors[name];

      setErrors(tmpErrors);
    }

    //change values
    onChange(val, name);
  }

  function submit() {
    let newBook = { ...book };
    delete newBook["_id"];
    delete newBook["__v"];

    if (useCase != "SEARCH") {
      //validate
      const tmpErrors = validateForm(newBook, schema);

      setErrors(tmpErrors || {});
      if (tmpErrors) return;
    }

    //submit
    onSubmit();
  }

  function close() {
    setErrors({});
    onClose();
  }

  return (
    <React.Fragment>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Βιβλίο</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Συμπληρώστε τα παρακάτω πεδία με τους σωστούς τύπους. Σε περίτπωση
            λάθους εμφανίζεται κόκκινη ένδειξη.
          </DialogContentText>
          <InputField
            error={errors.bookNumber ? true : false}
            helperText={errors.bookNumber}
            value={book.bookNumber}
            label="Αριθμός Τεκμηρίου"
            name="bookNumber"
            onChange={change}
          />
          <InputField
            error={errors.title ? true : false}
            helperText={errors.title}
            value={book.title}
            label="Τίτλος"
            name="title"
            onChange={change}
          />
          <InputField
            error={errors.author0 ? true : false}
            helperText={errors.author0}
            value={book.author0}
            label="Συγγραφέας"
            name="author0"
            onChange={change}
          />
          <InputField
            error={errors.author1 ? true : false}
            helperText={errors.author1}
            value={book.author1}
            label="Συγγραφέας"
            name="author1"
            onChange={change}
          />
          <InputField
            error={errors.author2 ? true : false}
            helperText={errors.author2}
            value={book.author2}
            label="Συγγραφέας"
            name="author2"
            onChange={change}
          />
          <InputField
            error={errors.author3 ? true : false}
            helperText={errors.author3}
            value={book.author3}
            label="Συγγραφέας"
            name="author3"
            onChange={change}
          />
          <InputField
            error={errors.publisher ? true : false}
            helperText={errors.publisher}
            value={book.publisher}
            label="Εκδότης"
            name="publisher"
            onChange={change}
          />
          <InputField
            error={errors.year ? true : false}
            helperText={errors.year}
            value={book.year}
            label="Έτος Έκδοσης"
            name="year"
            onChange={change}
          />
          <SelectField
            error={errors.type ? true : false}
            helperText={errors.type}
            size="small"
            label="Τύπος Τεκμηρίου"
            menu={typeMenuItems}
            name="type"
            value={book.type}
            onChange={change}
          />
          <SelectField
            error={errors.bookCollection ? true : false}
            helperText={errors.bookCollection}
            size="small"
            label="Συλλογή"
            menu={collectionMenuItems}
            name="bookCollection"
            value={book.bookCollection}
            onChange={change}
          />
          <SelectField
            error={errors.acquisition ? true : false}
            helperText={errors.acquisition}
            size="small"
            label="Τρόπος Πρόσκτησης"
            menu={acquisitionMenuItems}
            name="acquisition"
            value={book.acquisition}
            onChange={change}
          />
          <InputField
            error={errors.extraMaterial ? true : false}
            helperText={errors.student}
            value={book.extraMaterial}
            label="Συνοδευτικό Υλικό"
            name="extraMaterial"
            onChange={change}
          />
          <InputField
            error={errors.student ? true : false}
            helperText={errors.student}
            value={book.student}
            label="Παραγγέλων Τεκμηρίου"
            name="student"
            onChange={change}
          />
          <SelectField
            error={errors.department ? true : false}
            helperText={errors.department}
            size="small"
            label="Σχολή Παραγγέλοντα"
            menu={departmentMenuItems}
            name="department"
            value={book.department}
            onChange={change}
          />
          <InputField
            error={errors.supplier ? true : false}
            helperText={errors.supplier}
            value={book.supplier}
            label="Προμηθευτής"
            name="supplier"
            onChange={change}
          />
          <InputField
            error={errors.invoice ? true : false}
            helperText={errors.invoice}
            value={book.invoice}
            label="Αριθμός Τιμολογίου"
            name="invoice"
            onChange={change}
          />
          <InputField
            error={errors.price ? true : false}
            helperText={errors.price}
            value={book.price}
            label="Τιμή"
            name="price"
            onChange={change}
          />
          <SelectField
            error={errors.currency ? true : false}
            helperText={errors.currency}
            size="small"
            label="Νόμισμα"
            menu={currencyMenuItems}
            name="currency"
            value={book.currency}
            onChange={change}
          />

          <InputField
            error={errors.comments ? true : false}
            helperText={errors.comments}
            value={book.comments}
            label="Σχόλια"
            name="comments"
            onChange={change}
          />
        </DialogContent>
        <DialogActions>
          <Box
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button onClick={onClearForm}>Clear Form</Button>
            </Box>
            <Box>
              <Button onClick={close}>Cancel</Button>
              <Button onClick={submit}>Submit</Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default BookForm;
