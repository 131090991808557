import * as React from "react";

import CollapsibleRow from "./CollapsibleRow";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function CollapsibleTable(props) {
  const { books, onEdit, onDelete } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Αριθμός Τεκμηρίου</TableCell>
            <TableCell>Τίτλος</TableCell>
            <TableCell>Συγγραφέας</TableCell>
            <TableCell>Εκδότης</TableCell>
            <TableCell>Έτος έκδοσης</TableCell>
            <TableCell>Τύπος</TableCell>
            <TableCell>Συλλογή</TableCell>
            <TableCell>Πρόσκτηση</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {books.map((book) => (
            <CollapsibleRow
              key={book._id}
              book={book}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
