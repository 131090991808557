import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

import CollapsibleTable from "./CollapsibleTable";
import Layout from "../layout/Layout";
import BookForm from "./BookForm";

import { getBooksRequest } from "../../services/bookService";
import { addBookRequest } from "../../services/bookService";
import { updateBookRequest } from "../../services/bookService";
import { deleteBookRequest } from "../../services/bookService";
import { searchBookRequest } from "../../services/bookService";
import { exportToCSV } from "../../services/bookService";
import { paginate } from "../utils/paginate";

import { Box } from "@mui/material/";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { Pagination } from "@mui/material/";
import { Typography } from "@mui/material/";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CSVLink } from "react-csv";
import Logo from "../../images/logo.jpg";
import CheckboxList from "./CheckboxList";

function Feed() {
  const [books, setBooks] = useState([]);
  const [formBook, setFormBook] = useState({});
  const [formUseCase, setFormUseCase] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteDiagOpen, setDeleteDiagOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalBooksNumber, setTotalBooksNumber] = useState(0);
  const [exportBooks, setExportBooks] = useState([]);
  const [deleteBook, setDeleteBook] = useState({});

  const pageSize = 5;

  useEffect(() => {
    async function getAllBooks() {
      try {
        await fetchBooks(formBook, currentPage);
      } catch (err) {
        if (err.response && err.response.status < 500) {
          toast.error(err.response.data);
        }
      }
    }

    getAllBooks();
  }, [currentPage]);

  function handleEdit(book) {
    const tmpBook = { ...book };
    const authors = tmpBook.authors;
    delete tmpBook.authors;
    tmpBook.author0 = authors[0] || "";
    tmpBook.author1 = authors[1] || "";
    tmpBook.author2 = authors[2] || "";
    tmpBook.author3 = authors[3] || "";

    setFormUseCase("EDIT");
    setFormBook(tmpBook);
    setOpen(true);
  }

  function handleAdd() {
    setFormUseCase("ADD");
    setFormBook({});
    setOpen(true);
  }

  function handleSearch() {
    setFormUseCase("SEARCH");
    setOpen(true);
  }
  function handleClose() {
    setFormBook({});
    setOpen(false);
  }

  async function handleSubmit() {
    //case add
    if (formUseCase === "ADD") {
      await addBook();
      setFormBook({});
    }
    //case search
    else if (formUseCase === "SEARCH") {
      await searchBooks();
    }
    //case edit
    else {
      await editBook();
      setFormBook({});
    }

    setOpen(false);
  }

  async function searchBooks() {
    //send form book to server
    //server will use the fields of this object
    //to do the db query
    try {
      await fetchBooks(formBook, 1);
      setCurrentPage(1);
    } catch (err) {
      if (err.response && err.response.status < 500)
        toast.error(err.response.data);
    }
  }

  async function editBook() {
    const updatedBooks = [...books];

    try {
      const { data: editedBook } = await updateBookRequest(formBook);
      const idx = updatedBooks.findIndex((book) => book._id === editedBook._id);
      updatedBooks[idx] = editedBook;
      setBooks(updatedBooks);
      toast.success("Το τεκμήριο ανανεώθηκε επιτυχώς");
    } catch (err) {
      if (err.response && err.response.status < 500) {
        toast.error(err.response.data);
      }
    }
  }

  async function addBook() {
    try {
      const book = { ...formBook };
      const date = new Date();
      book.registrationYear = date.getFullYear();
      await addBookRequest(book);
      const succesString = `Το τεκμήριο ${formBook.bookNumber} προστέθηκε επιτυχώς`;
      setCurrentPage(1);
      setFormBook({});
      await fetchBooks({}, 1);
      toast.success(succesString);
    } catch (err) {
      if (err.response && err.response.status < 500) {
        toast.error(err.response.data);
      }
    }
  }

  function handleDelete(book) {
    setDeleteDiagOpen(true);
    setDeleteBook(book);
  }

  function handleDeleteDiagClose() {
    setDeleteDiagOpen(false);
  }

  async function doDelete() {
    try {
      await deleteBookRequest(deleteBook);
      setDeleteBook({});
      const isLastPage =
        currentPage == Math.floor(totalBooksNumber / pageSize + 1);
      if (
        isLastPage &&
        currentPage > 1 &&
        (totalBooksNumber - 1) % pageSize == 0
      ) {
        setCurrentPage(currentPage - 1);
        return;
      }
      await fetchBooks(formBook, currentPage);
      setDeleteDiagOpen(false);
      toast.success("Το τεκμήριο εχει διαγραφεί επιτυχώς");
    } catch (err) {
      if (err.response && err.response.status < 500) {
        toast.error(err.response.data);
      }
    }
  }

  function handleChange(val, name) {
    const book = { ...formBook };
    book[name] = val;
    setFormBook(book);
  }

  // function handleOpenForm(useCase) {
  //   setFormBook({});
  //   setFormUseCase(useCase);
  //   setOpen(true);
  // }

  function handlePageChange(e, page) {
    setCurrentPage(page);
  }

  async function fetchBooks(filter, pageNumber) {
    const { data } = await searchBookRequest(filter, pageSize, pageNumber);
    if (data == "EMPTY") {
      setTotalBooksNumber(0);
      setBooks([]);
      return;
    }

    const receivedBooks = data.books;
    const totalCount = data.count;

    setTotalBooksNumber(totalCount);
    setBooks(receivedBooks);
  }

  function handleClearForm() {
    setFormBook({});
  }

  const totalPages = Math.ceil(totalBooksNumber / pageSize);

  async function handleReload() {
    setExportBooks([]);
    await fetchBooks({}, 1);
    setCurrentPage(1);
    setFormBook({});
  }

  async function handleExport() {
    try {
      // const { data } = await exportToCSV();
      // console.log(data);
      const { data } = await getBooksRequest();
      setExportBooks(data);
    } catch (err) {
      toast.error(err.response.data && err.response.status < 500);
    }
  }

  async function feedDatabase() {
    let bookNumber = 991310001;

    for (let i = 0; i < 52; i++) {
      bookNumber = bookNumber + 1;
      let title = "TITLE".concat(i.toString());
      let author0 = "AUTHOR".concat(i.toString());
      let acquisition = "ΕΥΔΟΞΟΣ";

      let testBook = {
        bookNumber: bookNumber,
        title: title,
        author0: author0,
        acquisition: acquisition,
      };
      await addBookRequest(testBook);
    }
  }

  async function handleToggleFilter(year) {
    const book = { ...formBook };

    if (year == -1) {
      delete book.registrationYear;
    } else {
      book.registrationYear = year;
    }
    setFormBook(book);

    await fetchBooks(book, 1);
  }

  function getExportHeaders() {
    return [
      "title",
      "authors",
      "publisher",
      "year",
      "type",
      "bookCollection",
      "acquisition",
      "student",
      "department",
      "supplier",
      "extraMaterial",
      "invoice",
      "price",
      "currency",
      "comments",
    ];
  }

  return (
    <React.Fragment>
      <Layout>
        <Box display="flex" justifyContent="flex-start" marginTop="70px">
          <img src={Logo} alt="Logo" width={280} />
        </Box>
        <Box sx={{ marginTop: 5, marginX: 3 }}>
          <Box display="flex" justifyContent="flex-start">
            <Box marginTop="0">
              <CheckboxList onToggle={handleToggleFilter}></CheckboxList>
            </Box>
            <Box sx={{ marginLeft: 2, width: "100%" }}>
              <Stack
                direction="row"
                spacing={2}
                marginBottom={2}
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Button variant="outlined" onClick={handleAdd}>
                    Add Book
                  </Button>
                  <Button variant="outlined" onClick={handleSearch}>
                    Search
                  </Button>
                </Stack>
                <Button variant="outlined" onClick={handleReload}>
                  Refresh
                </Button>
              </Stack>
              <CollapsibleTable
                books={books}
                onEdit={handleEdit}
                onDelete={handleDelete}
              ></CollapsibleTable>
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 3,
                }}
              >
                <Box>
                  <Typography variant="body2">
                    Συνολικός αριθμός αποτελεσμάτων: {totalBooksNumber}
                  </Typography>
                </Box>
                <Box>
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={currentPage}
                    onChange={(e, p) => handlePageChange(e, p)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <Button onClick={feedDatabase}>Feed DB</Button> */}
        {exportBooks.length == 0 && (
          <Button variant="outlined" color="primary" onClick={handleExport}>
            Export Database
          </Button>
        )}
        {exportBooks.length > 0 && (
          <CSVLink
            data={exportBooks}
            headers={getExportHeaders()}
            filename={"booksDatabase.csv"}
            onClick={handleReload}
          >
            Download Result
          </CSVLink>
        )}
      </Layout>
      <BookForm
        open={open}
        book={formBook}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onClearForm={handleClearForm}
        onChange={handleChange}
        useCase={formUseCase}
      />
      <Dialog
        open={deleteDiagOpen}
        onClose={handleDeleteDiagClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {deleteBook.bookNumber} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDiagClose}>Cancel</Button>
          <Button onClick={doDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Feed;
