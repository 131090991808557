import http from "./httpService";

// const apiEndpoint = "http://localhost:3001/api/books";
const apiEndpoint = process.env.REACT_APP_API_URL + "/books";
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    "x-auth-token": token,
  },
};

function bookUrl(toAppend) {
  return `${apiEndpoint}/${toAppend}`;
}

/* Get all books */
export function getBooksRequest(pageSize, pageNumber) {
  // const enrichedConfig = { ...config };
  // enrichedConfig.params = {
  //   pageNumber: pageNumber,
  //   pageSize: pageSize,
  // };

  return http.get(apiEndpoint, config);
}

// export function getBookRequest() {
//   return http.get(apiEndpoint);
// }

export function addBookRequest(book) {
  cleanFormBook(book);
  mergeAuthors(book);

  return http.post(apiEndpoint, book, config);
}

export function updateBookRequest(book) {
  const id = book._id;
  mergeAuthors(book);
  const cleanBook = cleanFormBook(book);
  return http.put(bookUrl(id), cleanBook, config);
}

export function deleteBookRequest(book) {
  return http.delete(bookUrl(book._id), config);
}

export function searchBookRequest(filter, pageSize, pageNumber) {
  const enrichedConfig = { ...config };
  enrichedConfig.params = {
    pageNumber: pageNumber,
    pageSize: pageSize,
  };

  const filterCopy = Object.assign({}, filter);

  cleanFormBook(filterCopy);
  mergeAuthors(filterCopy);

  return http.post(bookUrl("search"), filterCopy, enrichedConfig);
}

export function exportToCSV() {
  return http.get(bookUrl("/export"), config);
}

function removeAccents(book) {
  const toRemoveAccents = [
    "title",
    "author",
    "publisher",
    "supplier",
    "student",
  ];
  for (let key in book) {
    if (toRemoveAccents.includes(key))
      book[key] = book[key].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
}

function mergeAuthors(book) {
  let authors = [book.author0, book.author1, book.author2, book.author3];
  let validAuthors = authors.filter(
    (author) => author != undefined && author.length > 0
  );
  delete book.author0;
  delete book.author1;
  delete book.author2;
  delete book.author3;
  book.authors = validAuthors;
}

function cleanFormBook(book) {
  delete book["_id"];
  delete book["__v"];
  for (let key in book) {
    // if (book[key].length == 0) delete book[key];
  }
  return book;
}
