import * as React from "react";
import { useState } from "react";

import { AppBar } from "@mui/material/";
import { Toolbar } from "@mui/material/";
import { Typography } from "@mui/material/";
import { Box } from "@mui/material/";
import { Button } from "@mui/material";

import { logout } from "../utils/loginTools";
import { isLoggedIn } from "../utils/loginTools";

import Logo from "../../images/logo.jpg"; // Replace './my-image.jpg' with the actual path to your image file

function Navbar(props) {
  return (
    <React.Fragment>
      <Box>
        <AppBar>
          <Toolbar
            variant="dense"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {/* <img
                src={Logo}
                width="180px"
                height="50px"
                alt="Description of the image"
              /> */}
              {/* <Typography>TUC Library E-Book</Typography> */}
            </Box>
            <Box>
              {isLoggedIn() && (
                <Button variant="text" color="inherit" onClick={logout}>
                  Logout
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}

export default Navbar;
