import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/material";
import { Typography } from "@mui/material/";

export default function CheckboxList(props) {
  const { onToggle } = props;
  const [years, setYears] = React.useState([
    2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ]);
  // const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [];

    if (currentIndex === -1) {
      newChecked.push(value);
      onToggle(value);
    } else {
      newChecked.splice(currentIndex, 1);
      onToggle(-1);
    }

    setChecked(newChecked);
  };

  function handleAdd() {
    const lastYear = years[years.length - 1];
    const newYears = [...years];
    newYears.push(lastYear + 1);
    setYears(newYears);
  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Typography variant="subtitle1" color="primary">
          Year
        </Typography> */}
        <List
          sx={{
            width: "100%",
            maxWidth: 150,
            maxHeight: 300,
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 2,
            border: "1px solid gainsboro",
          }}
        >
          {years.map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem key={value} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value}`} />
                </ListItemButton>
              </ListItem>
            );
          })}
          <Box display="flex" justifyContent="center">
            <IconButton onClick={handleAdd}>
              <AddIcon />
            </IconButton>
          </Box>
        </List>
      </Box>
    </React.Fragment>
  );
}
