import Joi from "joi";
import {
  getAsquisitionEnum,
  getCurrenciesEnum,
} from "../../services/menuItemsService";

export const schema = Joi.object({
  bookNumber: Joi.number()
    .custom(validateBookNumberField, "bookNumber-validation")
    .required()
    .messages({
      "any.custom": "Το πεδίο πρέπει να ξεκινάει με 9913100",
      "number.base": "Υποχρεωτικό πεδίο αποτελούμενο αυστηρά απο αριθμούς",
    }),
  title: Joi.string().required().messages({
    "string.empty": "Το πεδίο είναι υποχρεωτικό",
    "any.required": "Το πεδίο είναι υποχρεωτικό",
  }),
  author0: Joi.string().required().messages({
    "string.empty": "Το πεδίο είναι υποχρεωτικό",
    "any.required": "Το πεδίο είναι υποχρεωτικό",
  }),
  author1: Joi.string().allow(""),
  author2: Joi.string().allow(""),
  author3: Joi.string().allow(""),
  student: Joi.string().allow(""),
  publisher: Joi.any(),
  year: Joi.number().allow(null, "").messages({
    "number.base": "Το πεδίο πρέπει να είναι αριθμός",
  }),
  type: Joi.string().allow(""),
  bookCollection: Joi.string().allow(""),
  acquisition: Joi.any()
    .valid(...getAsquisitionEnum())
    .required()
    .messages({
      "string.empty": "Το πεδίο είναι υποχρεωτικό",
      "any.required": "Το πεδίο είναι υποχρεωτικό",
    }),
  extraMaterial: Joi.string().allow(""),
  department: Joi.string().allow(""),
  supplier: Joi.string().allow(""),
  invoice: Joi.number().allow(null, "").messages({
    "number.base": "Το πεδίο πρέπει να είναι αριθμός",
  }),
  price: Joi.number().allow(null, "").messages({
    "number.base": "Το πεδίο πρέπει να είναι αριθμός",
  }),
  currency: Joi.any()
    .valid(...getCurrenciesEnum())
    .messages({
      "string.empty": "Το πεδίο είναι υποχρεωτικό",
      "any.required": "Το πεδίο είναι υποχρεωτικό",
    }),
  comments: Joi.string().allow(""),
  registrationYear: Joi.number().allow(null, ""),
});

function validateBookNumberField(value, helpers) {
  if (value < 9913100)
    throw new Error("Το πεδίο πρέπει να έχει πρόθεμα το: 9913100");
}
