import { logoutRequest } from "../../services/authService";

export async function logout() {
  // const token = window.localStorage.getItem("token");
  try {
    const token = window.localStorage.getItem("token");
    await logoutRequest(token);
    window.localStorage.removeItem("token");
    window.location = "/";
  } catch (ex) {}
}
export function isLoggedIn() {
  return window.localStorage.getItem("token");
}
