import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Stack } from "@mui/material";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemText } from "@mui/material";

function CollapsibleRow(props) {
  const { book, onEdit, onDelete } = props;
  const [open, setOpen] = React.useState(false);

  function generatePriceString() {
    if (!book.price) return "-";

    if (book.price && book.currency)
      return book.price.toString().concat(" " + book.currency);

    if (book.price && !book.currency) return book.price;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {book.bookNumber || "-"}
        </TableCell>
        <TableCell>{book.title || "-"}</TableCell>
        <TableCell>
          {/* <List dense={true}>
            {book.authors.map((author) => (
              <ListItem key={author}>
                <ListItemText
                  primary={author}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
            ))}
          </List> */}
          <List dense={true}>
            <ListItem>
              <ListItemText primary={book.authors[0]} />
            </ListItem>
            <ListItem>
              {book.authors[1] && <ListItemText primary={book.authors[1]} />}
            </ListItem>
            <ListItem>
              {book.authors[2] && <ListItemText primary={book.authors[2]} />}
            </ListItem>
            <ListItem>
              {book.authors[3] && <ListItemText primary={book.authors[3]} />}
            </ListItem>
          </List>
        </TableCell>

        <TableCell>{book.publisher || "-"}</TableCell>
        <TableCell>{book.year || "-"}</TableCell>
        <TableCell>{book.type || "-"}</TableCell>
        <TableCell>{book.bookCollection || "-"}</TableCell>
        <TableCell>{book.acquisition || "-"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Περισσότερες Πληροφορίες
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Παραγγέλων Τεκμηρίου</TableCell>
                    <TableCell>Σχολή Παραγγέλοντα</TableCell>
                    <TableCell>Προμηθευτής</TableCell>
                    <TableCell>Συνοδευτικό Υλικό</TableCell>
                    <TableCell>Αριθμός Τιμολογίου</TableCell>
                    <TableCell>Τιμή</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {book.student || "-"}
                    </TableCell>
                    <TableCell>{book.department || "-"}</TableCell>
                    <TableCell>{book.supplier || "-"}</TableCell>
                    <TableCell>{book.extraMaterial || "-"}</TableCell>
                    <TableCell>{book.invoice || "-"}</TableCell>
                    <TableCell>{generatePriceString()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Typography
              marginTop={2}
              variant="subtitle2"
              gutterBottom
              component="div"
            >
              Σχόλια
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography variant="body2">{book.comments || "-"}</Typography>
              <Box>
                <Button size="small" onClick={() => onEdit(book)}>
                  <EditIcon color="action" />
                </Button>
                <Button size="small" onClick={() => onDelete(book)}>
                  <DeleteIcon color="error" />
                </Button>
              </Box>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// CollapsibleRow.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

export default CollapsibleRow;
