export function getTypeEnum() {
  return [
    "ΕΝΤΥΠΟ",
    "ΒΙΝΤΕΟΔΙΣΚΟΣ (DVD)",
    "ΟΠΤΙΚΟΣ ΔΙΣΚΟΣ (CD)",
    "ΚΑΣΕΤΑ ΗΧΟΥ",
    "ΔΙΣΚΟΣ ΗΧΟΥ (CD)",
    "ΑΤΛΑΣ",
    "ΧΑΡΤΗΣ",
    "ΦΥΛΛΑΔΙΟ",
    "ΠΛΗΡΟΦΟΡΙΑΚΟ ΕΓΧΕΙΡΙΔΙΟ",
    "ΒΙΒΛΙΟ ΑΠΑΝΤΗΣΕΩΝ",
    "ΠΑΡΤΗΤΟΥΡΑ",
    "ΔΙΑΦΑΝΕΙΕΣ",
    "ΑΦΙΣΑ",
    "ΕΙΚΟΝΑ",
    "ΚΑΡΤΑ ΕΠΙΔΕΙΞΗΣ",
    "ΓΚΡΑΒΟΥΡΑ",
    "ΔΙΑΓΡΑΜΜΑΤΑ",
    "ΑΛΛΟ",
  ];
}

export function getCollectionEnum() {
  return [
    "ΟΠΤΙΚΟΑΚΟΥΣΤΙΚΟ ΥΛΙΚΟ",
    "ΑΡΧΕΙΑ ΥΠΟΛΟΓΙΣΤΩΝ",
    "ΔΙΠΛΩΜΑΤΙΚΕΣ",
    "ΠΟΛΥΜΕΣΑ",
    "ΚΥΡΙΑ ΣΥΛΛΟΓΗ",
    "ΧΑΡΤΕΣ",
    "ΥΠΕΡΜΕΓΕΘΗ",
    "ΠΛΗΡΟΦΟΡΙΑΚΑ",
    "ΑΝΑΤΥΠΑ",
    "ΚΛΕΙΣΤΗ ΣΥΛΛΟΓΗ",
    "ΠΡΟΤΥΠΑ",
    "ΕΞΑΜΗΝΟΥ",
    "ΑΠΟΣΥΡΣΗ - ΟΠΤΙΚΟΑΚΟΥΣΤΚΟ ΥΛΙΚΟ",
    "ΑΠΟΣΥΡΣΗ - ΑΡΧΕΙΑ ΥΠΟΛΟΓΙΣΤΩΝ",
    "ΑΠΟΣΥΡΣΗ - ΔΙΠΛΩΜΑΤΙΚΕΣ",
    "ΑΠΟΣΥΡΣΗ - ΠΟΛΥΜΕΣΑ",
    "ΑΠΟΣΥΡΣΗ - ΚΥΡΙΑ ΣΥΛΛΟΓΗ",
    "ΑΠΟΣΥΡΣΗ - ΠΛΗΡΟΦΟΡΙΑΚΑ",
    "ΑΠΟΣΥΡΣΗ - ΠΡΟΤΥΠΑ",
    "ΑΠΟΣΥΡΣΗ - ΕΞΑΜΗΝΟΥ",
  ];
}

// export function getAsquisitionEnum() {
//   return ["ΑΓΟΡΑ", "ΔΩΡΕΑ", "ΧΑΜΕΝΟ", "ΧΑΜΕΝΟ ΠΛΗΡΩΜΕΝΟ", "ΑΛΛΟ"];
// }
export function getAsquisitionEnum() {
  return [
    "ΕΥΔΟΞΟΣ",
    "ΤΑΚΤΙΚΟΣ ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ",
    "ΔΩΡΕΑ",
    "ΕΠΙΣΤΡΟΦΗ ΕΥΔΟΞΟΥ",
    "ΑΝΤΙΚΑΤΑΣΤΑΣΗ ΧΑΜΕΝΟΥ/ΚΑΤΕΣΤΡΑΜΜΕΝΟΥ ΤΕΚΜΗΡΙΟΥ",
    "ΑΛΛΟ",
  ];
}

export function getDepartmentEnum() {
  return ["ΗΜΜΥ", "ΜΠΔ", "ΑΡΜΗΧ", "ΧΗΜΗΠΕΡ", "ΜΗΧΟΠ", "ΑΛΛΟ"];
}

export function getCurrenciesEnum() {
  return ["€", "$", "£"];
}
