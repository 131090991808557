import React from "react";
import LandingPage from "./components/login-page/LandingPage";
import Feed from "./components/feed-page/Feed";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./components/common/ProtectedRoute";

import { isLoggedIn } from "./components/utils/loginTools";

// function isLoggedIn() {
//   return window.localStorage.getItem("token");
// }

function App() {
  return (
    <div>
      <React.Fragment>
        <Routes>
          <Route
            path="/"
            element={(!isLoggedIn() && <LandingPage />) || <Feed />}
          ></Route>
          <Route path="*" element={<Navigate to="/" replace />}></Route>
          <Route
            path="/feed"
            element={
              <ProtectedRoute isAllowed={isLoggedIn()} redirectPath="/">
                <Feed />
              </ProtectedRoute>
            }
          ></Route>
        </Routes>
        <ToastContainer />
      </React.Fragment>
    </div>
  );
}

export default App;
