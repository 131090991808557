import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/login";

export function login(credentials) {
  return http.post(apiEndpoint, credentials);
}

export function logoutRequest(token) {
  console.log(token);
  const config = {
    headers: {
      "x-auth-token": token,
    },
  };
  return http.delete(`${process.env.REACT_APP_API_URL}/logout`, config);
}
